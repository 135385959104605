import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import {Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import {Cacheable} from 'ngx-cacheable';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })

export class UserService {
    public static readonly BASEURL = '/users';
    router;

    isSportFrequencyMandatory = false;
    sportFrequency = false;
    isPathologyMandatory = false;
    pathology = false;
    isHeightWeightBmiMandatory = false;
    heightWeightBmi = false;

    isBirthdateMandatory = false;
    birthdate = false;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    getAll() {
        return this.http.get<any>(environment.URLAPI + UserService.BASEURL);
    }

    getCountActive() {
        return this.http.get<any>(environment.URLAPI + UserService.BASEURL + '/count');
    }

    getUser(hash) {
        let url = environment.URLAPI + UserService.BASEURL + '/getHash/' + hash;
        return this.http.get<User>(url).toPromise()
    }

    getPodoCad() {
        return this.http.get<any>(environment.URLAPI + '/podo-cad');
    }

    @Cacheable()
    getRoles() {
        return this.http.get<any>(environment.URLAPI + UserService.BASEURL + '/roles/getAll ');
    }

    @Cacheable()
    getAddressesType(): Observable<User> {
        let url = environment.URLAPI + '/users/addresses/types/getAll';
        return this.http.get<User>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteUser(user: User): Observable<User[]> {
        let hash = user.hash;
        let url = environment.URLAPI + UserService.BASEURL + '/softdelete/' + hash;
        return this.http.delete( url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    createUser(user: User): Observable<User> {
        let url = environment.URLAPI + UserService.BASEURL + '/add/';
        return this.http.post<User>(url, user, httpOptions);
    }

    editUser(user: User): Observable<User> {
        let hash = user.hash;
        let url = environment.URLAPI + UserService.BASEURL + '/edit/' + hash;
        return this.http.post<User>(url, user, httpOptions);
    }

    getFields(hash) {
        const url = environment.URLAPI + UserService.BASEURL  + '/' + hash + '/fields/';
        return this.http.get( url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    setMandatoryFields(user) {
        if (user.userField.pathology === 2) {
            this.isPathologyMandatory = true;
            this.pathology = true;
        } else if (user.userField.pathology === 1) {
            this.pathology = true;
        }

        if (user.userField.sportFrequency === 2) {
            this.isSportFrequencyMandatory = true;
            this.sportFrequency = true;
        } else if (user.userField.sportFrequency === 1) {
            this.sportFrequency = true;
        }

        if (user.userField.heightWeightBmi === 2) {
            this.isHeightWeightBmiMandatory = true;
            this.heightWeightBmi = true;
        } else if (user.userField.heightWeightBmi === 1) {
            this.heightWeightBmi = true;
        }

        if (user.userField.birthdate === 2) {
            this.birthdate = true;
            this.isBirthdateMandatory = true;
        } else if (user.userField.birthdate === 1) {
            this.birthdate = true;
        }
    }

    setMandatoryFieldsForCardManage(userField) {
        if (userField.pathology === 2) {
            this.isPathologyMandatory = true;
            this.pathology = true;
        } else if (userField.pathology === 1) {
            this.pathology = true;
        }

        if (userField.sportFrequency === 2) {
            this.isSportFrequencyMandatory = true;
            this.sportFrequency = true;
        } else if (userField.sportFrequency === 1) {
            this.sportFrequency = true;
        }

        if (userField.heightWeightBmi === 2) {
            this.isHeightWeightBmiMandatory = true;
            this.heightWeightBmi = true;
        } else if (userField.heightWeightBmi === 1) {
            this.heightWeightBmi = true;
        }
    }

    transformMandatoryFieldsToInteger(user, isUpdating: boolean) {
        let pat = 0;
        if (this.pathology) {
            pat++;
            if (this.isPathologyMandatory) {
                pat++;
            }
        }

        let hwb = 0;
        if (this.heightWeightBmi) {
            hwb++;
            if (this.isHeightWeightBmiMandatory) {
                hwb++;
            }
        }

        let sf = 0;
        if (this.sportFrequency) {
            sf++;
            if (this.isSportFrequencyMandatory) {
                sf++;
            }
        }

        let bd = 0;
        if (this.birthdate) {
            bd++;
            if (this.isBirthdateMandatory) {
                bd++;
            }
        }

        if (isUpdating) {
            user.userField.pathology = pat;
            user.userField.heightWeightBmi = hwb;
            user.userField.sportFrequency = sf;
            user.userField.birthdate = bd;

        } else {
            user.pathology = pat;
            user.heightWeightBmi = hwb;
            user.sportFrequency = sf;
            user.birthdate = bd;
        }


    }

    resetMandatoryFields() {
        this.isSportFrequencyMandatory = false;
        this.sportFrequency = false;
        this.isPathologyMandatory = false;
        this.pathology = false;
        this.isHeightWeightBmiMandatory = false;
        this.heightWeightBmi = false;
        this.birthdate = false;
        this.isBirthdateMandatory = false;
    }

    shouldDisplayMandatoryCardsFields() {
        return this.sportFrequency || this.pathology || this.heightWeightBmi;
    }

    shouldDisplayMandatoryUsersFields() {
        return this.birthdate;
    }

    resetPreferences() {
        this.isSportFrequencyMandatory = false;
        this.sportFrequency = false;
        this.isPathologyMandatory = false;
        this.pathology = false;
        this.isHeightWeightBmiMandatory = false;
        this.heightWeightBmi = false;

        this.isBirthdateMandatory = false;
        this.birthdate = false;
    }
}
