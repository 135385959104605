import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from '../../_services';
import {LoginService} from '../../_repositories';
import {environment} from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CheckConnectionGuard implements CanActivate {

    constructor(private router: Router, private login: LoginService, private authentification: AuthenticationService) { }

    canActivate() {
        if (!this.authentification.isLoginIn) {
            this.check();
        }
        setInterval(() => {
            this.check();
        }, 30000);
        return true;
    }

    check() {
        if (typeof(environment.CHECK_TOKEN) !== 'undefined' && environment.CHECK_TOKEN === true) {
            this.login.checkToken().subscribe(value => {
                if (value.result === true) {
                    this.updateAccountBalance(value.accountBalance.balance)
                    return true;
                }
                else {
                    this.authentification.logout();
                    location.reload(true);
                    return false;
                }
            }, error => {
                this.authentification.logout();
                location.reload(true)
                return false;
            });
        }
    }

    updateAccountBalance(newValue) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'))
        localStorage.setItem('currentUser',
            JSON.stringify({
                'id': currentUser.id,
                'username': currentUser.username,
                'token': currentUser.token,
                'firstname': currentUser.firstname,
                'lastname': currentUser.lastname,
                'hash': currentUser.hash,
                'roles': currentUser.roles,
                'balance': newValue
            })
        )
    }
}
