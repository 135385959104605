import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule as Ng2Charts } from 'ng2-charts';
import {TranslateModule} from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TooltipRoutingModule } from './tooltip-routing.module';
import { TooltipComponent } from './tooltip.component';

@NgModule({
    imports: [CommonModule, Ng2Charts, TranslateModule, TooltipRoutingModule, NgbModule],
    declarations: [TooltipComponent],
    exports: [TooltipComponent]
})
export class TooltipModule {}
