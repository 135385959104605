import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { AuthGuard, CheckConnectionGuard } from './shared';

const routes: Routes = [
    { path: 'demo', loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule) },
    { path: 'demo/login/:hash/:code', loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule) },
    { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard, CheckConnectionGuard] },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'subscribe', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
    { path: 'losingpassword', loadChildren: () => import('./losing-password/losingpassword.module').then(m => m.LosingPasswordModule) },
    { path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },
    { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
    // Use for maintenance purposes
    // { path: '**', redirectTo: '/' },
    // {
    //     path: '',
    //     component: MaintenancePageComponent
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
