import {Component, Input, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import { environment } from '../../../../environments/environment';
import {AuthenticationService} from '../../../_services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router, Event, NavigationStart, NavigationEnd} from '@angular/router';
import {DatatableService} from '../../services/DatatableService';
import {CardService} from '../../../_repositories';
import {first,  filter } from 'rxjs/operators';
import {ModalUndoService} from '../../../_services/modal/modalUndoService';
import {DatatableModalDeleteService} from '../../services/DatatableModalDeleteService';
import { UpdateStatusModalService } from '../../services/UpdateStatusModalService';
import {ExportDBService} from '../../../_repositories';
import * as moment from 'moment';


@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {
  //  @Output() onFilter: EventEmitter<any> = new EventEmitter();
    @Input() model: string;
    @Input() heading;
    @Input() baseUrl: string;
    @Input() actions;
    @Input() order = [[ 0, "DESC" ]];
    @Input() filter;
    headingnew;
    isHeaderSet = false;
    language = 'fr';
    paramQuery: any;    // parameter from url
    translatedHeading;
    inProgress = false;
    routerSubscription;
    public tableWidget: any;
    isInitialized = false;

    constructor(
        private cardService: CardService,
        public _dataTableService: DatatableService,
        private _dataTableModalDeleteService: DatatableModalDeleteService,
        private authentification: AuthenticationService,
        private _modaleUndo: ModalUndoService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private router: Router,
        private _updateStatusModalService: UpdateStatusModalService,
        private exportDBService: ExportDBService,
    ) {
        this._dataTableService.router = this.router;
        this.routerSubscription = router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            if (this.isInitialized) {
                this._dataTableService.initCurrentPage();
                this._dataTableService.loadDatas(true);
            }
        });
    }

    ngOnInit() {
        this.isHeaderSet = false;
        this._dataTableService.model = this.model;
        this._dataTableService.heading = this.heading;
        this._dataTableService.filterAction = this.filter;
        this.initParamQuery();
        this._dataTableService.initCurrentPage();
        this.initLimitResultPerPage();
        this.isInitialized = true;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._dataTableService.reset();
            this._dataTableService.heading = this.heading;
            this.isHeaderSet = true;
            this._dataTableService.loadDatas();
        })
        if (this.model == 'cards' || this.model =='usersCards') {
            this._dataTableService.loadStatus();
        }
    }

    ngOnDestroy() {
        this._dataTableService.currentPage = 1;
        this.isInitialized = false;
    }

    public initParamQuery() {
        this.activatedRoute.queryParams.pipe().subscribe(params => {
            this.paramQuery = params;
            this._dataTableService.paramQuery = this.paramQuery;
        });
    }

    public initLimitResultPerPage() {
        if (localStorage.getItem('limitResultPerPage')) {
            const limitResultNumber = parseInt(localStorage.getItem('limitResultPerPage'), 10);
            this._dataTableService.limitResultNumber = limitResultNumber;
            this._dataTableService.lastLimit = limitResultNumber;
        }
    }

    public getBackgroundByStatus(id) {
        let color = '';
        switch (id) {
            case 1: color = '#82CCDD'; break;
            case 2: color = '#60A3BC'; break;
            case 3: color = '#0A3D62'; break;
            case 4: color = '#290A62'; break;
            case 5: color = '#696969'; break;
            case 6: color = '#0A3D62'; break;
        }
        return color;
    }

    public updateStatus(card) {        
        if (this.model !== 'cards') {
            return;
        }
        this._updateStatusModalService.card = card;
        this._updateStatusModalService.shouldDisplay = true;

    }

    public doAction(action, index) {
        if (action.action == 'pods-excels') {
            this.downloadPodsHistoryExcel(
                this._dataTableService.items[index].hash,
                this._dataTableService.items[index].firstname,
                this._dataTableService.items[index].lastname
            );
            return;
        }
        if (action.action == "pdf") {
            this.downloadFile(this._dataTableService.items[index].hash);
            return;
        }
        if (action.action == "delete") {
            let identifiant;
            if (this._dataTableService.items[index].hash) {
                identifiant = this._dataTableService.items[index].hash;
            } else {
                identifiant = this._dataTableService.items[index].id;
            }

            const formatedObject = this.formatLink(action.link, index);
            const url = formatedObject.links.join('/');
            const reloadCallback = function() {
                this._dataTableService.items.splice(index, 1);
                this._dataTableService.loadDatas();
            }
            this._dataTableModalDeleteService.filter('open',
                {
                    'identifiant': identifiant,
                    'model': this.model,
                    'url': url,
                    'reloadCallback': reloadCallback,
                    'item': this._dataTableService.items[index]
                });

            return;
        }
        this.formatLinkAndNavigate(action.link, index);

    }

    public formatLinkAndNavigate(link, index) {
        const formatedObject = this.formatLink(link, index);
        let queryParams = formatedObject.queryParams;
        let links = formatedObject.links;

        this.navigate(links, queryParams);
    }

    public formatLink(link, index) {
        let queryParams = null;
        let links = link.split('/');

        for (let i = 0 ; i < links.length ; ++i) {
            if (links[i].includes(":")) {
                links[i] = this.replaceParams(links[i], index);
            }
        }

        links = links.join('/');
        if (links.includes("?")) {
            const linkWithQuery = this.extractQueryParams(links, index);
            queryParams = linkWithQuery.queryParams;
            links = linkWithQuery.baseLink;
        }
        links = links.split('/');
        return {links, queryParams};
    }

    public navigate(links, queryParams = null) {
        if (!queryParams) {
            this.router.navigate(links);
        } else {
            this.router.navigate(links, {queryParams: queryParams});
        }
    }

    public replaceParams(name, index) {
        let nameArray = name.split(":");
        return nameArray[0] + this._dataTableService.items[index][nameArray[1]];
    }

    public extractQueryParams(name, index) {
        let urlArr = name.split("?");
        let paramQueryArr = urlArr[1].split(/[=&]+/);
        let params = {};
        for (let i = 0 ; i < paramQueryArr.length ; i+=2) {
            let key = paramQueryArr[i];
            let value = paramQueryArr[i+1];
            params[key] = value;
        }
        return {
            baseLink: urlArr[0],
            queryParams: params
        };
    }

    public shouldDisplayCardStatus(indice) {
        const statusIndice = this._dataTableService.items[indice].status_id;
        let roles = this.authentification.getRoles();
        const m = this._dataTableService.model;
        if (m == "usersCards") {
            return true;
        }
        if (m == "cards") {
            return this._dataTableService.isAuthorized(roles, statusIndice);
        }
        return false;
    }

    public yesOrNo(value) {
        if (value) {
            return 'Yes';
        } else {
            return 'No';
        }
    }

    public showDayName(date) {
        if (date) {
            let dt = moment(date, "DD/MM/YYYY");
            let dayName = dt.format('dddd');
            let translatedDay = this.translate.instant(dayName);
            let fullDate = date + " " + "(" + translatedDay + ")"

            return fullDate;
        }
        return ""
    }

    public async downloadFile(hash): Promise<void> {
        document.body.style.cursor = 'progress';
        $('.downloadFile').css('cursor', 'progress');

        this.cardService.downloadResource(hash).subscribe(response => {
            if(response.ok){
                var file = new Blob([response.body], {type: 'application/pdf'});
                let downloadUrl = URL.createObjectURL(response.body);
                window.open(downloadUrl);
                document.body.style.cursor = 'default';
                $('.downloadFile').css('cursor', 'pointer');
            }

        }, error => {
            document.body.style.cursor = 'default';
            $('.downloadFile').css('cursor', 'pointer');
        });
    }

    public async downloadPodsHistoryExcel(hash, firstname, lastname): Promise<void> {
        document.body.style.cursor = 'progress';
        $('.downloadFile').css('cursor', 'progress');

        this.exportDBService.getUserPodsHistory(hash).subscribe(response => {
            if(response.ok){
                let downloadUrl = URL.createObjectURL(response.body);
                let a = document.createElement('a');
                a.download = 'PODS_History_' + firstname + '_' + lastname;
                a.href = downloadUrl;
                document.body.appendChild(a);
                a.click();
                a.remove();
                document.body.style.cursor = 'default';
                $('.downloadFile').css('cursor', 'pointer');
            }

        }, error => {
            document.body.style.cursor = 'default';
            $('.downloadFile').css('cursor', 'pointer');
        });
    }

    filteredActions(indice) {
        if (this.model != 'usersCards' && this.model != 'cards') {
            return this.actions;
        }
        let authorizedActions = [...this.actions];

        //remove edit and delete for not admin user
        let roles = this.authentification.getRoles();
        if (this.model == "cards") {
            let deleteActionIndice =  authorizedActions.map((e) => { return e.action; }).indexOf('delete');
            let editActionIndice = authorizedActions.map((e) => { return e.action; }).indexOf('edit');
            if (!this._dataTableService.isAdmin(roles)) {
                authorizedActions.splice(deleteActionIndice, 1);
                authorizedActions.splice(editActionIndice, 1);
            }
        } else if (this.model == "usersCards") {
            //we remove delete and dupliacate actions in usersCards
            if (this._dataTableService.items[indice].status_id < 2) {
                let duplicateActionIndice =  authorizedActions.map((e) => { return e.action; }).indexOf('duplicate');
                authorizedActions.splice(duplicateActionIndice, 1);
            }
            if (this._dataTableService.items[indice].status_id > 1) {
                let deleteActionIndice = authorizedActions.map((e) => { return e.action; }).indexOf('delete');
                authorizedActions.splice(deleteActionIndice, 1);
                //Remove edit action if status id != 1
                let editActionIndice = authorizedActions.map((e) => { return e.action; }).indexOf('edit');
                authorizedActions.splice(editActionIndice, 1);
            }
        }

        // if (this._dataTableService.items[indice].shoeModelName === "3dp") {
        //     let duplicateActionIndice =  authorizedActions.map((e) => { return e.action; }).indexOf('duplicate');
        //     authorizedActions.splice(duplicateActionIndice, 1);
        // }
        return authorizedActions;
    }

    setPage(page) {
        this._dataTableService.setPage(page, this.router);
    }

    getHeading () {
        return this.heading;
    }

}
