import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({providedIn: 'root'})

export class BalanceHistoryService {
    data;
    baseUrl = '/balance-history'
    constructor(private http: HttpClient) {
    }

    insertBalanceHistory(balanceHistoryLine) {
        let url = environment.URLAPI + this.baseUrl + '/insert';
        return this.http.post(url, balanceHistoryLine, httpOptions)
    }

}
