<div class="row">
    <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h2 class="modal-title">ID: {{ item.id }}</h2>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>{{ 'Are you sure to delete this item?' | translate }}</p>
                <div *ngIf="changedFields.length > 0" id="confirm_duplicate_footer">
                        <div class="confirm_duplicate_footer_field_change">
                            <ul>
                                <li *ngFor="let field of changedFields">{{ field }}</li>
                            </ul>
                        </div>

                </div>


            </div>
        <div class="modal-footer">
            <a style="color: #ffffff;" class="btn btn-danger " (click)="deleteItems();c('Close click');">{{'Delete'}}</a>
            <a type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Close'| translate}}</a>
        </div>
    </ng-template>
</div>
