import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Pathology } from '../_models/pathology';
import {SportService} from './sport.service';
import {Cacheable} from 'ngx-cacheable';

@Injectable({ providedIn: 'root' })

export class PathologyService {
    public static readonly BASEURL = '/pathologies';

    constructor(private http: HttpClient) { }

    @Cacheable()
    getAll() {
        return this.http.get<Pathology[]>(environment.URLAPI + PathologyService.BASEURL);
    }

    getAllPathologies() {
        return this.http.get<Pathology[]>(environment.URLAPI + PathologyService.BASEURL + '/getAllPatho/');
    }

    createPathology(pathology) {
        return this.http.post(environment.URLAPI + PathologyService.BASEURL + '/add/', pathology);
    }

    editPathology(pathology) {
        let id = pathology.id;
        return this.http.post(environment.URLAPI + PathologyService.BASEURL + '/edit/' + id , pathology);
    }

    getPathology(id) {
        return this.http.get<Pathology>(environment.URLAPI + PathologyService.BASEURL + '/get/' + id);
    }

    deletePathology(pathology) {
        let id = pathology.id;
        return this.http.delete(environment.URLAPI + PathologyService.BASEURL + '/delete/' + id , pathology);
    }
}
