import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Files, Report, User} from '../_models';

@Injectable({ providedIn: 'root' })

export class ReportService {
    public static readonly BASEURL = '/cards/reports';
    router;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    getAll() {
        return this.http.get<Report[]>(environment.URLAPI + ReportService.BASEURL);
    }
    
    get(hash): Observable<any> {
        let url = environment.URLAPI + ReportService.BASEURL + '/' + hash;
        return this.http.get<Report>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    getPhrases(type) {
        let param = {};

        if (type !== null) {
            param = {params: {type: type}};
        }
        return this.http.get<any>(environment.URLAPI + ReportService.BASEURL + '/phrases/', param);
    }

    getFiles(hash) {
        return this.http.get<any>(environment.URLAPI + ReportService.BASEURL + '/' + hash + '/files/', {});
    }

    sendMail(hash) {
        return this.http.get<any>(environment.URLAPI + ReportService.BASEURL + '/' + hash + '/sendmail', {});
    }

    edit(hash, report: Report): Observable<User> {
        const url = environment.URLAPI + ReportService.BASEURL + '/' + hash + '/edit';
        return this.http.put<User>(url, report)
            .pipe(
                catchError(this.handleError)
            );
    }
}
