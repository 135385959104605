export class Files {
    files_id: '';
    files_name = '';
    files_hash = '';
    files_type = '';
    cards_reports_files_note_videos: string = '';
    cards_reports_files_videos_enabled = false;
    cards_reports_files_note_files: string = '';
    cards_reports_files_files_enabled = false;
    cards_reports_files_note = '';
    cards_reports_files_id: '';
    cards_reports_files_type_name = '';
    files_size = '';
}
