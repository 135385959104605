import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { File } from "../_models/file";

@Injectable({ providedIn: "root" })
export class FileService {
  public static readonly BASEURL = "/files";

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<File[]>(environment.URLAPI + FileService.BASEURL);
  }

  delete(hash) {
    return this.http.delete(
      environment.URLAPI + FileService.BASEURL + "/" + hash + "/delete"
    );
  }

  download(hash) {
    return this.http
      .get(environment.URLAPI + FileService.BASEURL + "/" + hash + "/download")
      .subscribe({
        next: (res: any) => {
          return this.http
            .get(res.data.url, {
              responseType: "blob",
            })
            .subscribe((response: any) => {


              console.log("RESPONSE",response)

              const blob = new Blob([response],{
                type: res.data.type
              });
              const data = window.URL.createObjectURL(blob);
              var link = document.createElement("a");
              link.href = data;
              link.target = "_blank";

              link.download = res.data.filename;
              document.body.appendChild(link);
              link.click();
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);

                document.body.style.cursor = "default";
                $(".btn").css("cursor", "pointer");
              }, 100);
            });
        },
      });
  }

  urlUpload() {
    return environment.URLAPI + FileService.BASEURL + "/" + "upload";
  }

  upload(formData) {
    return this.http.post(this.urlUpload(), formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  attachToCard(hash, hashCard) {
    return this.http.put(
      environment.URLAPI + FileService.BASEURL + "/" + hash + "/attachtocard",
      { card_hash: hashCard }
    );
  }
}
