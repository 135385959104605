import { Component, OnInit } from '@angular/core';
import {MessageService} from '../../../_repositories';
import {MessagesDisplayService} from '../../../_services';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
    messageService;
    titleMessage;
    messageMessage;
    activeMessage;
    colorPoliceMessage;
    colorBackMessage;

  constructor(message: MessageService, private _messageService: MessagesDisplayService) {
      this.messageService = message;
      if (this._messageService.getListObservers().length === 0) {
          this._messageService.listen().subscribe((m: any) => {
              switch (m.key) {
                  case 'Reinitialize':
                      this.CallReInitMessage();
                      break;
              }
          });
      }
  }

  ngOnInit() {
      this.CallReInitMessage();
  }

    CallReInitMessage() {
        this.messageService.getActiveMessage().subscribe((response) => {
            if (typeof(response[0]) !== 'undefined') {
                this.titleMessage = response[0].title;
                this.messageMessage = response[0].message;
                this.activeMessage = response[0].active;
                this.colorPoliceMessage = response[0].colorPolice;
                this.colorBackMessage = response[0].colorBack;
            }
        });
    }

}
