import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Sport} from '../_models/sport';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {Router} from '@angular/router';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({providedIn: 'root'})

export class ExportDBService {
    public static readonly BASEURL = '/exportdb';
    router;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    getAll(type): Observable<any> {
        return this.http.get(environment.URLAPI + ExportDBService.BASEURL + '/' + type,
            {observe: 'response', responseType: 'blob'} );
    }

    getUserPodsHistory(userHash): Observable<any> {
        return this.http.get(environment.URLAPI + ExportDBService.BASEURL + '/18?hash=' + userHash,
            {observe: 'response', responseType: 'blob'} );
    }
}
