import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {LoginService, UserService} from '../_repositories';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public isLoginIn: boolean = false;
    constructor(private http: HttpClient, private loginService: LoginService) { }

    public login(username: string, password: string) {
        return this.loginService.login({ login: username, password: password })
            .toPromise()
            .then(res =>{
                if (res && res.token) {
                    localStorage.setItem('currentUser',
                        JSON.stringify({
                            'id': res.user.id,
                            'username': username,
                            'token': res.token,
                            'firstname': res.user.firstname,
                            'lastname': res.user.lastname,
                            'hash': res.user.hash,
                            'roles': res.user.roles,
                            'balance': res.user.balanceAccount.balance
                        })
                    );
                }
                return res
            })
            .catch(err =>{
                return err
            })
    }

    public getCurrentUser(){
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        return currentUser;
    }

    public getToken() {
        let currentUser = this.getCurrentUser();

        if (currentUser && currentUser.token) {
            return currentUser.token;
        } else {
            return null;
        }

    }

    public getHash(){
        let currentUser = this.getCurrentUser();

        if (currentUser && currentUser.hash) {
            return currentUser.hash;
        } else {
            return null;
        }
    }

    public getFirstname() {
        let currentUser = this.getCurrentUser();

        if (currentUser && currentUser.firstname) {
            return currentUser.firstname;
        } else {
            return null;
        }
    }

    public getRoles() {
        let currentUser = this.getCurrentUser();

        if (currentUser && currentUser.roles) {
            return currentUser.roles;
        } else {
            return null;
        }
    }

    public getLastname() {
        let currentUser = this.getCurrentUser();

        if (currentUser && currentUser.lastname) {
            return currentUser.lastname;
        } else {
            return null;
        }
    }

    public getUsername() {
        let currentUser = this.getCurrentUser();

        if (currentUser && currentUser.username) {
            return currentUser.username;
        } else {
            return null;
        }
    }

    public getCurrentBalance(): Number {
        let currentUser = this.getCurrentUser();
        if (currentUser && currentUser.balance) {
            return currentUser.balance
        }
        return 0
    }

    public logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}
