import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Status } from '../_models/status';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Cacheable} from 'ngx-cacheable';

@Injectable({ providedIn: 'root' })

export class StatusService {
    public static readonly BASEURL = '/status';

    constructor(private http: HttpClient) { }

    getAll() {
        let option = {
            params : {
                'order_column' : 's.position',
                'order_dir' : 'ASC'
            }
        };

        return this.http.get<any>(environment.URLAPI + StatusService.BASEURL + '/', option);
    }

    createStatus(status) {
        return this.http.post(environment.URLAPI + StatusService.BASEURL + '/add/', status);
    }

    @Cacheable()
    getAllStatus(): Observable<Status> {
        let url = environment.URLAPI + StatusService.BASEURL + '/getAllStatus/';
        return this.http.get<Status[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    editStatus(status) {
        let id = status.id;
        return this.http.post(environment.URLAPI + StatusService.BASEURL + '/edit/' + id , status);
    }

    getStatus(id) {
        return this.http.get<Status>(environment.URLAPI + StatusService.BASEURL + '/get/' + id);
    }

    deleteStatus(status) {
        let id = status.id;
        return this.http.delete(environment.URLAPI + StatusService.BASEURL + '/delete/' + id , status);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
