import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Recovery } from '../_models/recovery';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    cache: true
};

@Injectable({ providedIn: 'root' })

export class ShoesModelsService {
    public static readonly BASEURL = '/shoesmodels';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Recovery[]>(environment.URLAPI + ShoesModelsService.BASEURL);
    }

    get(id) {
        return this.http.get<any>(environment.URLAPI + ShoesModelsService.BASEURL + '/' + id);
    }

    edit(shoeModel) {
        let id = shoeModel.id;
        const url = environment.URLAPI + ShoesModelsService.BASEURL + '/' + id;
        return this.http.put(url, shoeModel, httpOptions)
    }

    getPathimage(id) {
        return environment.URLAPI + ShoesModelsService.BASEURL + '/' + id + '/image';
    }
}
