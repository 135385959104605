import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {DatatableService} from '../../../services/DatatableService';
import {DatatableModalDeleteService} from '../../../services/DatatableModalDeleteService';
import {TranslateService} from '@ngx-translate/core';
import {
    CardService,
    CardsTemplatesService, GabaritService,
    MaterialService,
    MessageService, PathologyService,
    PatientService, RecoveryService,
    SportService, StatusService,
    UserService, UsersWaitingLineService
} from '../../../../_repositories';
import {Card, Gabarit, Materials, Message, Patient, Sport, User, Status, Pathology, Recovery} from '../../../../_models';
import {LayoutService} from '../../../../_services/LayoutService';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-modal-datatable-delete',
    templateUrl: './modalDelete.component.html',
    styleUrls: ['./modalDelete.component.scss']
})
export class ModalDeleteComponent implements OnInit {
    closeResult: string;
    changedFields: Array<string> = [];

    identifiant: null;
    model: null;
    url;

    paramQuery;
    filterStatus;

    reloadCallback = null;
    item = null;

    constructor(private modalService: NgbModal,
                private activatedRoute: ActivatedRoute,
                private cardService: CardService,
                private templateService: CardsTemplatesService,
                private messageService: MessageService,
                private userService: UserService,
                private patientService: PatientService,
                private sportService: SportService,
                private materialService: MaterialService,
                private gabaritService: GabaritService,
                private statusService: StatusService,
                private pathologyService: PathologyService,
                private recoveryService: RecoveryService,
                private usersWaitingLine: UsersWaitingLineService,

                private router: Router,
                private translate: TranslateService,
                private _datatableModalDeleteService: DatatableModalDeleteService,
                private _dataTableService: DatatableService,
                private _layoutService: LayoutService) {
        this._datatableModalDeleteService.listen().subscribe((m: any) => {
            //           console.log('Fire onFilterClick: ', m.key);

            console.log(m);
            switch (m.key) {
                case 'open':
                    console.log('open');
                    this.identifiant = m.value.identifiant;
                    this.model = m.value.model;
                    this.url = m.value.url;
                    this.item = m.value.item
                    if (m.value.reloadCallback) {
                        this.reloadCallback = m.value.reloadCallback;
                    }

                    this.open();
                    break;
            }
        });

        this.activatedRoute.queryParams.pipe().subscribe(params => {
            this.paramQuery = params;
        });

        if (typeof(this.paramQuery.status) !== 'undefined') {
            this.filterStatus = parseInt(this.paramQuery.status);
        }
    }

    ngOnInit() {
    }

    // https://stackoverflow.com/questions/31548311/angular-html-binding
    @ViewChild('content', {static: true}) dataContainer: ElementRef;
    open(content = this.dataContainer) {
        this.modalService.open(content, {centered: true }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    deleteItems() {
        switch (this.model) {
            case 'materials':
                const material = new Materials();
                material.id = this.identifiant;

                this.materialService.deleteMaterial(material).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'status':
                const status = new Status();
                status.id = this.identifiant;

                this.statusService.deleteStatus(status).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'gabarits':
                const gabarit = new Gabarit();
                gabarit.id = this.identifiant;

                this.gabaritService.deleteGabarit(gabarit).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'pathologies':
                const pathology = new Pathology();
                pathology.id = this.identifiant;

                this.pathologyService.deletePathology(pathology).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'recoveries':
                const recovery = new Recovery();
                recovery.id = this.identifiant;

                this.recoveryService.deleteRecovery(recovery).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'sports':
                const sport = new Sport();
                sport.id = this.identifiant;

                this.sportService.deleteSport(sport).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'usersWaitingLine':
                this.usersWaitingLine.delete(this.identifiant).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'messages':
                const message = new Message();
                message.id = this.identifiant;

                this.messageService.deleteMessage(message).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'users':
                const user = new User();
                user.hash = this.identifiant;

                this.userService.deleteUser(user).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'patients':
            case 'usersPatients':
                const patient = new Patient();
                patient.hash = this.identifiant;

                this.patientService.deletePatient(patient).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'cards':
            case 'usersCards':
                const card = new Card();
                card.hash = this.identifiant;
                this.cardService.deleteCard(card).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'cardsTemplates':
                this.templateService.delete(this.identifiant).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                    this.showMessageError();
                });
                break;
            case 'usersCardsTemplates':
                this.templateService.unlinkUserToTemplate(this.getCurrentUserId(), this.identifiant).subscribe((response) => {
                    this.showMessageSuccess();
                }, () => {
                   this.showMessageError();
                });
                break;
            default:
                this.router.navigateByUrl(this.url);
                break;
        }
    }

    private showMessageError() {
        this._layoutService.filter('addAlert', {'status': 'danger', 'message': this.translate.instant('ERROR: Item not deleted because an error') });
        setTimeout(() => {
            this._layoutService.filter('removeAllAlert', {});
        }, 5000);
    }

    private showMessageSuccess() {
        this._dataTableService.filter('Reinitialize', {'status' : this.filterStatus});
        this._layoutService.filter('addAlert', {'status': 'success', 'message': this.translate.instant('Item deleted with success') });
        if (this.reloadCallback) {
            this.reloadCallback();
        }
        setTimeout(() => {
            this._layoutService.filter('removeAllAlert', {});
        }, 5000);
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    private getCurrentUserId() {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return user.id;
    }
}
