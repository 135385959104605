import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Sport} from '../_models/sport';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {Router} from '@angular/router';
import {Cacheable} from 'ngx-cacheable';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({providedIn: 'root'})

export class SportService {
    public static readonly BASEURL = '/sports';
    router;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    getAll(): Observable<Sport> {
        let url = environment.URLAPI + SportService.BASEURL;
        return this.http.get<Sport[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    @Cacheable()
    getAllSports(): Observable<Sport> {
        let url = environment.URLAPI + SportService.BASEURL + '/getAll/';
        return this.http.get<Sport[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    createSport(sport: Sport): Observable<Sport> {
        let url = environment.URLAPI + SportService.BASEURL + '/add/';
        return this.http.post<Sport>(url, sport, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    editSport(sport: Sport): Observable<Sport> {
        let id = sport.id;
        let url = environment.URLAPI + SportService.BASEURL + '/edit/' + id;
        return this.http.post<Sport>(url, sport, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getSport(id): Observable<Sport> {
        let url = environment.URLAPI + SportService.BASEURL + '/get/' + id;
        return this.http.get<Sport>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteSport(sport: Sport): Observable<Sport[]> {
        let id = sport.id;
        let url = environment.URLAPI + SportService.BASEURL + '/delete/' + id;
        return this.http.delete( url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
