import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UsersCardsTemplates } from '../_models/usersCardsTemplates';

@Injectable({ providedIn: 'root' })

export class UsersCardsTemplatesService {
    public static readonly BASEURL = '/users/cards/templates';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<UsersCardsTemplates[]>(environment.URLAPI + UsersCardsTemplatesService.BASEURL);
    }
}
