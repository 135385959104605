import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UsersCards} from '../_models/usersCards';
import {User} from '../_models';

@Injectable({providedIn: 'root'})

export class UsersCardsService {
    public static readonly BASEURL = '/users/cards';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<any>(environment.URLAPI + UsersCardsService.BASEURL + '/');
    }

    getCountBystatus() {
        return this.http.get<any>(environment.URLAPI + UsersCardsService.BASEURL + '/count/by/group');
    }

    createSport(sport) {
        return this.http.post(environment.URLAPI + UsersCardsService.BASEURL + '/add/', sport);
    }

    editSport(sport) {
        let id = sport.id;
        return this.http.post(environment.URLAPI + UsersCardsService.BASEURL + '/edit/' + id , sport);
    }

    getSport(id) {
        return this.http.get<UsersCards>(environment.URLAPI + UsersCardsService.BASEURL + '/get/' + id);
    }
}
