import { Injectable } from "@angular/core";
@Injectable()
export class Recovery {
    id: number;
    name: string;
    is3dp = false;
    activated = true;
    price: number;
}

export interface Recovery {
    is3dp: boolean;
    activated: boolean;
}