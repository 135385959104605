<div class="maintenance-page">
  <img
    class="image"
    src="assets/images/maintenance.jpg"
    alt="site en maintenance"
    srcset=""
  />
  <h3>labo.gespodo.com est actuellement en maintenance.</h3>
  <h6>Le site sera denouveau accessible à 18h</h6>
</div>
