import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
    @Input() img: string;
    @Input() contentText: string;
    @Input() position: string = 'right';
}
