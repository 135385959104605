import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UsersPatients } from '../_models/usersPatients';
import {Cacheable} from 'ngx-cacheable';

@Injectable({ providedIn: 'root' })

export class UsersPatientsService {
    public static readonly BASEURL = '/users/patients';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<UsersPatients[]>(environment.URLAPI + UsersPatientsService.BASEURL + '/list/');
    }

    getAllUserPatient() {
        return this.http.get<UsersPatients[]>(environment.URLAPI + UsersPatientsService.BASEURL + '/getAllUserPatient/');
    }

    getAllUserWaitingPatient() {
        return this.http.get<UsersPatients[]>(environment.URLAPI + UsersPatientsService.BASEURL + '/getAllUserWaitingPatient/');
    }
}
