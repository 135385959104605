import {Files} from './report/files';
import { Injectable } from "@angular/core";

@Injectable()
export class Report {
    anamnesis = '';
    conclusion = '';
    greetings = '';
    card_report_field_detorsion = true;
    card_report_field_axe = true;
    card_report_field_courbure = true;
    card_report_field_hemicoupole = true;
    card_report_field_cuvette = true;
    card_report_field_epaisseur = true;
    card_report_field_talon = true;
    card_report_field_pronateur = true;
    card_report_field_supinateur = true;
    card_report_field_brc = true;
    card_report_field_brc23Ext = true;
    card_report_field_extMorton = true;
    card_report_field_brc23Int = true;
    card_report_field_appuiRC = true;
    card_report_field_sousAC = true;
    card_report_field_diaphysaire = true;
    card_report_field_firstRayon = true;
    card_report_field_decharge = true;
    card_report_field_cluffy = true;
    card_report_field_arrPied = true;
    card_report_field_avPied = true;
    card_report_field_cuboidien = true;
    card_report_field_largeur = true;
    card_report_field_bardePronatrice = true;
    card_report_field_posting = true;
    card_report_field_ruler = true;
    card_report_field_posturoEpi = true;
    card_report_field_posturoEmi = true;
    card_report_field_posturoEca = true;
    card_report_field_posturoEai = true;
    card_report_field_posturoBpi = true;
    card_report_field_posturoBi = true;
    card_report_field_posturoBa = true;
    card_report_field_painlevel1 = true;
    card_report_data_ceintScapul = '';
    card_report_data_bassRachi = '';
    card_report_data_genG = '';
    card_report_data_genD = '';
    card_report_data_piedCheG = '';
    card_report_data_piedCheD = '';
    card_report_email_patient = '';
    card_report_email_other = '';
    card_report_email_doctor = '';
    card_report_email_kine = '';
    card_report_email_osteo = '';
    shoe_type_name = '';
    user_hash = '';
    listFiles: Array<Files>;
}