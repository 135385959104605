import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    cache: true
};

@Injectable({ providedIn: 'root' })

export class FinishingService {
    public static readonly BASEURL = '/finishings';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(environment.URLAPI + FinishingService.BASEURL);
    }

    get(id) {
        return this.http.get<any>(environment.URLAPI + FinishingService.BASEURL + '/' + id);
    }

    edit(finishing) {
        let id = finishing.id;
        const url = environment.URLAPI + FinishingService.BASEURL + '/' + id;
        return this.http.put(url, finishing, httpOptions)
    }

    updateFinishingsProductionDays(ids: number[], finshingsProductionDaysValues: number[]): Observable<any> {
        const data = {
          finishingsIds: ids,
          finshingsProductionDaysValues: finshingsProductionDaysValues
        };
        return this.http.put(`${environment.URLAPI}/finishings/production-days`, data);
    }
}
