import { Injectable } from "@angular/core";
@Injectable()
export class Card {
    id: number;
    user_id: number;
    patient_id: number;
    card_data_id: number;
    card_patient_id: number;
    card_patient_hash: string;
    patient_lastname: string;
    patient_firstname: string;
    patient_gender: string;
    card_data_shoesSize: number;
    card_data_shoesSizeScale: string;
    card_data_selectedShippingDate: Date;
    patho_id: number;
    shoeModel_id: number;
    footspecs: string;
    hash: string;
    user_hash: string;
    podoCad: string;
}