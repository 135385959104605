import { Injectable } from "@angular/core";
@Injectable()
export class Patient {
    id = '';
    user_id = '';
    patient_id = '';
    card_data_id = '';
    firstname = '';
    lastname = '';
    birthdate = '';
    gender = '';
    wheight = '';
    height = '';
    email = '';
    hash = '';
    static birthdate: any;
}
