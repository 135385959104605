<div class="card text-white bg-{{bgClass}}">
    <div class="card-header">
        <div class="row">
            <div class="col col-xs-2 card-header-icon">
                <i class="fa {{icon}} fa-4x"></i>
            </div>
            <div class="col col-xs-10 text-right card-header-text">
                <div class="d-block huge">{{count}}</div>
                <div class="d-block">{{label | translate}}</div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <span class="float-left">{{'More' | translate}} {{data}}</span>
        <a href="javascript:void(0)" class="float-right card-inverse">
            <span ><i class="fa fa-arrow-circle-right"></i></span>
        </a>
    </div>
</div>
