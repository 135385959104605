export default (start, end, step, isIncrementing = true) => {
    let array = [];
    if (isIncrementing) {
        while (start < end) {
            array.push(start);
            start += step;
        }
        return array
    }
    while (start > end) {
        array.push(start);
        start -= step;
    }
    return array;
}