import { Injectable } from "@angular/core";
import fillArray from "../../_helpers/fillArray";
import { CardService } from "../../_repositories/card.service";
type GenericObject = { [key: string]: any };

@Injectable({
  providedIn: "root",
})
export class SelectedDataService {
  public mode = "add";
  public isLoading = false;
  public cardId: number = null;
  public cardHash: string = null;
  public currentCard = null;

  // general info
  public currentUser: GenericObject = null;
  public patients: GenericObject[] = [];
  public waitingPatients: GenericObject[] = [];
  public selectedPatient: GenericObject = null;

  public pathologies: GenericObject[] = [];
  public selectedPathology: GenericObject = null;

  public shoeSizeScales: string[] = ["EU", "UK", "US"];
  public selectedShoeSizeScale: string = "EU";
  public selectedShoeSize: number = 40;

  public selectedHeight: number = null;
  public selectedWeight: number = null;

  public cardOwnerFirstname: string = null;
  public cardOwnerLastname: string = null;

  public parentId: number = null;
  public parentCardShoeModel: GenericObject = null;

  // sport
  public sports: GenericObject[] = [];
  public selectedSport: GenericObject = null;
  public selectedSportFrequency: string = null;
  public selectedSportActivity: string = null;

  // EVA
  public painlevel1: number = null;

  // Only STL
  public onlyStl: boolean = false;

  // Width
  public largeur: string = "";

  //diagnostic note
  public selectedDiagnosticNote: string = "";

  //diagnostic note
  public selectedTherapeuticNote: string = "";

  //products & services
  public products: GenericObject[] = [];
  public selectedProduct: GenericObject = null;
  public services: GenericObject[] = [];
  public selectedService: GenericObject = null;

  //order
  public materials: GenericObject[] = [];
  public selectedMaterial: GenericObject = null;
  public finishings: GenericObject[] = [];
  public selectedFinishing: GenericObject = null;
  public recoveries: GenericObject[] = [];
  public selectedRecovery: GenericObject = null;
  public flangeColors: GenericObject[] = [];
  public selectedFlangeColor: GenericObject = null;
  public therapeuticFieldPrices : GenericObject = null

  //shipping
  public shippings: GenericObject[] = [];
  public selectedShipping: GenericObject = null;
  public isUrgentSelected: boolean = false;
  public selectedShippingDate: Date = null;
  public selectedShippingAddress = {
    street: "",
    number: "",
    city: "",
    postCode: "",
    country: "",
  };

  public rawQrCode: GenericObject = null;
  public stringifiedQrCode: string = "";

  //shipping note
  public selectedShippingNote: string = "";

  //template
  public templates = [];
  public selectedTemplate = null;

  // 3DP
  public selectedLeftModel = null;
  public selectedRightModel = null;
  public selectedLeftMortonLoc = null;
  public selectedRightMortonLoc = null;

  // 4DP
  public insoleLength = "full";
  public topSkin: "none";
  public latticeSelection: "stochastic";
  public latticeDensity: "20";

  // FDM 
  public filling = null;

  //temporary footspecs
  public footspecs = null;
  public shouldGoToNextStepThree: boolean = false;

  public cardDataParentHash = null;

  constructor() { }

  ngOnDestroy() {
    this.reset();
  }

  public getShoeSizes(): number[] {
    if (this.selectedShoeSizeScale === "EU") {
      return fillArray(23, 56, 1);
    }
    if (this.selectedShoeSizeScale === "UK") {
      return fillArray(3, 14.5, 0.5);
    }
    if (this.selectedShoeSizeScale === "US") {
      return fillArray(3, 14.5, 0.5);
    }
    return [];
  }

  public filteredMaterials() {
    const serviceId = this.selectedService?.id;
    const isPosturoNewPair = serviceId == CardService.MODEL_POSTURO_NEW_PAIR;
    const isTongModelX = serviceId == CardService.MODEL_TONG_MODELE_X;
    const isTongModelY = serviceId == CardService.MODEL_TONG_MODELE_Y;
    const is3dp = this.selectedProduct?.id === 8
    const is4dp = this.selectedProduct?.id === 9
    const isFdm = this.selectedProduct?.id === 10
    const filteredMat = [];
    for (let material of this.materials) {
      if (material.stock > 5) {
        if (isPosturoNewPair) {
          if (material.isPosturo) {
            filteredMat.push(material);
          }
          continue;
        } else if (isTongModelX || isTongModelY) {
          if (material.isTong) {
            filteredMat.push(material);
          }
          continue; 
        } else if (is3dp) {
          if (material.is3dp) {
            filteredMat.push(material);
          }
          continue;
        } else if (is4dp) {
          if (material.is4dp) {
            filteredMat.push(material);
          }
          continue;
        } else if (isFdm) {
          if (material.isFdm) {
            filteredMat.push(material);
          }
          continue;
        } else {
          if (!material.is3dp && !material.is4dp && !material.isFdm) {
            filteredMat.push(material);
          }
        }
      }
    }

    return filteredMat;
  }

  public reset() {
    this.mode = "add";
    this.isLoading = false;
    this.cardId = null;
    this.cardHash = null;

    // general info
    this.currentUser = null;
    this.patients = [];
    this.waitingPatients = [];
    this.selectedPatient = null;

    this.pathologies = [];
    this.selectedPathology = null;

    this.shoeSizeScales = ["EU", "UK", "US"];
    this.selectedShoeSizeScale = "EU";
    this.selectedShoeSize = null;

    this.selectedHeight = null;
    this.selectedWeight = null;

    // sport
    this.sports = [];
    this.selectedSport = null;
    this.selectedSportFrequency = null;
    this.selectedSportActivity = null;

    // EVA
    this.painlevel1 = null;

    // 3DP
    this.selectedLeftModel = null;
    this.selectedRightModel = null;
    this.selectedLeftMortonLoc = null;
    this.selectedRightMortonLoc = null;

    // 4DP
    this.insoleLength = "full";
    this.topSkin = "none";
    this.latticeSelection = "stochastic";
    this.latticeDensity = "20";

    // FDM 
    this.filling = null;

    // Only STL
    this.onlyStl = false;

    // Width
    this.largeur = "normal";

    //diagnostic note
    this.selectedDiagnosticNote = "";

    //products & services
    this.products = [];
    this.selectedProduct = null;
    this.services = [];
    this.selectedService = null;

    //order
    this.materials = [];
    this.selectedMaterial = null;
    this.finishings = [];
    this.selectedFinishing = null;
    this.recoveries = [];
    this.selectedRecovery = null;
    this.flangeColors = [];
    this.selectedFlangeColor = null;

    //shipping
    this.shippings = [];
    this.selectedShipping = null;
    this.isUrgentSelected = false;
    this.selectedShippingDate = null;
    this.selectedShippingAddress = {
      street: "",
      number: "",
      city: "",
      postCode: "",
      country: "",
    };

    this.rawQrCode = null;
    this.stringifiedQrCode = "";

    //shipping note
    this.selectedShippingNote = "";

    //therapeutic note
    this.selectedTherapeuticNote = "";
    //template
    this.templates = [];
    this.selectedTemplate = null;

    this.cardDataParentHash = null;
    //temporary footspecs
    this.footspecs = null;

    this.parentCardShoeModel = null;
  }

  public reset3dpDatas() {
    this.selectedLeftModel = null;
    this.selectedRightModel = null;
    this.selectedLeftMortonLoc = null;
    this.selectedRightMortonLoc = null;
  }

  public shouldDisplayTherapeuticChoices() {
    return (
      this.selectedService?.id != CardService.MODEL_SEMMELLE_New_pair_Choice2 && this.selectedService?.id != CardService.MODEL_4DP_USINAGE && this.selectedService?.id != CardService.MODEL_FDM_USINAGE
    );
  }

  public outsourcingFabricationOnly() {
    return (
      this.selectedService?.id == CardService.MODEL_SEMMELLE_New_pair_Choice2
    );
  }

  public outsourcingDesignAndFabrication() {
    return (
      this.selectedService?.id == CardService.MODEL_SEMMELLE_New_pair_Choice1
    );
  }

  public shouldDisplayPosturoChoices() {
    return (
      this.selectedService?.id == CardService.MODEL_POSTURO_NEW_PAIR
    )
  }
}
