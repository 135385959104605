import { Injectable } from "@angular/core";
@Injectable()
export class Materials {
    id: number;
    name: string;
    stock: number;
    stockMin: number;
    isPosturo = false;
    isTong = false;
    is3dp = false;
    is4dp = false;
    isFdm = false;
    position: number;
    activated = true;
    materialGroup: number;
    soldBy: number;
    supplierId:number;
}

export interface Material {
    id?: number;
    materialGroup: number | object;
    name: string;
    stock: number;
    stockMin: number;
    isPosturo:boolean;
    isTong:boolean;
    is3dp:boolean;
    is4dp:boolean;
    isFdm:boolean;
    position: number;
    activated:boolean;
}