import { Injectable } from "@angular/core";
@Injectable()
export class Qualif {
    id: number;
    company: string;
    maturity: string;
    externalisation: string;
    nb_semelle: string;
    profil: string;
    profilAlter: string;
    freetext: string;
}
