import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from './datatable.component';
import {TranslateModule} from '@ngx-translate/core';
import {ModalDeleteModule} from './modalDelete/modalDelete.module';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ModalDeleteModule,
    MatTooltipModule,
    FormsModule
  ],
  declarations: [ DatatableComponent ],
  exports: [ DatatableComponent ]
})
export class DatatableModule { }
