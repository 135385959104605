import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Qualif} from '../_models/qualif';
import { Observable, of } from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({providedIn: 'root'})

export class QualifService {
    router;
    data;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    createQualif(qualif: Qualif, hash): Observable<Qualif> {
        let url = environment.DEMO + '/users/' + hash + '/endprocess';
        return this.http.put<Qualif>(url, qualif, httpOptions);
    }


    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
