import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CardsTemplates} from '../_models/cardsTemplates';
import {Observable} from 'rxjs';
import {Card} from '../_models';
import {catchError} from 'rxjs/operators';
import {Cacheable} from 'ngx-cacheable';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({providedIn: 'root'})

export class CardsTemplatesService {
    public static readonly BASEURL = '/cards/templates';

    constructor(private http: HttpClient) {
    }

    getTemplate(hash): Observable<Card> {
        let url = environment.URLAPI + CardsTemplatesService.BASEURL + '/' + hash;

        return this.http.get<Card>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    delete(hash): Observable<any> {
        let url = environment.URLAPI + CardsTemplatesService.BASEURL + '/' + hash;
        return this.http.delete( url, {})
            .pipe(
                catchError(this.handleError)
            );
    }

    // @Cacheable()
    getAll() {
        return this.http.get<CardsTemplates[]>(environment.URLAPI + CardsTemplatesService.BASEURL + '/allByUser/');
    }

    createTemplate(card: Card, footspecs): Observable<Card> {
        card.footspecs = footspecs;
        let url = environment.URLAPI + CardsTemplatesService.BASEURL + '/add/';
        return this.http.post<Card>(url, card, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    editTemplate(card: Card, footspecs, hash): Observable<Card> {
        card.footspecs = footspecs;
        let url = environment.URLAPI + CardsTemplatesService.BASEURL + '/' + hash + '/edit/';
        return this.http.post<Card>(url, card, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getSport(id) {
        return this.http.get<CardsTemplates>(environment.URLAPI + CardsTemplatesService.BASEURL + '/get/' + id);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    fetchLinkableUsersToTemplate(templateHash) {
        return this.http.get<any>(environment.URLAPI + CardsTemplatesService.BASEURL + '/' + templateHash + '/linkable-users');
    }
    
    linkUserToTemplate(idUser, templateHash) {
        return this.http.post<any>(environment.URLAPI + CardsTemplatesService.BASEURL + '/' + templateHash + '/link-with-user/' + idUser, {});
    }

    unlinkUserToTemplate(idUser, templateHash) {
        return this.http.post<any>(environment.URLAPI + CardsTemplatesService.BASEURL + '/' + templateHash + '/unlink-with-user/' + idUser, {});
    }
}
