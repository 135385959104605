import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Gabarit } from '../_models/gabarit';
import {SportService} from './sport.service';

@Injectable({ providedIn: 'root' })

export class GabaritService {
    public static readonly BASEURL = '/gabarits';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Gabarit[]>(environment.URLAPI + GabaritService.BASEURL);
    }

    createGabarit(gabarit) {
        return this.http.post(environment.URLAPI + GabaritService.BASEURL + '/add/', gabarit);
    }

    editGabarit(gabarit) {
        let id = gabarit.id;
        return this.http.post(environment.URLAPI + GabaritService.BASEURL + '/edit/' + id , gabarit);
    }

    getGabarit(id) {
        return this.http.get<Gabarit>(environment.URLAPI + GabaritService.BASEURL + '/get/' + id);
    }

    deleteGabarit(gabarit) {
        let id = gabarit.id;
        return this.http.delete(environment.URLAPI + GabaritService.BASEURL + '/delete/' + id , gabarit);
    }
}
