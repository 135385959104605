import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class LoginService {
    constructor(private http: HttpClient) { }

    login(parameters) {
        return this.http.post<any>(environment.URLAPI + '/external/user/login', parameters);
    }

    losingPassword(parameters) {
        return this.http.post<any>(environment.URLAPI + '/external/user/losing/password', parameters);
    }

    logout(parameters = []) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        let hashUser = '';
        if (currentUser && currentUser.hash) {
            hashUser = currentUser.hash;
        } else {
            hashUser = null;
        }

         return this.http.post<any>(environment.URLAPI + '/external/user/logout/' + hashUser, parameters);
    }

    checkToken() {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let hashUser = '';

        if (currentUser && currentUser.hash) {
            hashUser = currentUser.hash;
        } else {
            hashUser = null;
        }

        return this.http.get<any>(environment.URLAPI + '/external/user/checktoken/' + hashUser, {});
    }
}