// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//     production: false,
//     URLAPI: 'http://localhost:8000/api',
//     DEMO: 'http://localhost:8000/demo',
//     DEMO_DOWNLOAD: 'http://localhost:8000/demo/user/files/download',
//     CHECK_TOKEN: true,
//     PLAYSTORE_APP_URL: 'https://play.google.com/store/apps/details?id=com.gespodo',
//     REDIRECTION_SUBSCRIBE: 'https://www.gespodo.com/contact',
//     URL_CONDITION_GENERAL: 'https://www.gespodo.com/fr/CGU_FOOTSCAN3D'
// };

export const environment = {
    production: true,
    URLAPI: 'https://api.labo.gespodo.eu/api',
    DEMO: 'https://api.labo.gespodo.eu/demo',
    DEMO_DOWNLOAD: 'https://api.labo.gespodo.eu//demo/user/files/download',
    CHECK_TOKEN: true,
    PLAYSTORE_APP_URL: 'https://play.google.com/store/apps/details?id=com.gespodo',
    REDIRECTION_SUBSCRIBE: 'https://www.gespodo.com/contact',
    URL_CONDITION_GENERAL: 'https://www.gespodo.com/fr/CGU_FOOTSCAN3D'
};


// export const environment = {
//     production: false,
//     URLAPI: 'https://api.staging.labo.footfitr.com/api',
//     DEMO: 'https://api.staging.labo.footfitr.com/demo',
//     DEMO_DOWNLOAD: 'https://api.staging.labo.footfitr.com/demo/user/files/download',
//     CHECK_TOKEN: true,
//     PLAYSTORE_APP_URL: 'https://play.google.com/store/apps/details?id=com.gespodo',
//     REDIRECTION_SUBSCRIBE: 'https://www.gespodo.com/contact',
//     URL_CONDITION_GENERAL: 'https://www.gespodo.com/fr/CGU_FOOTSCAN3D'
// };

// export const environment = {
//     production: false,
//     URLAPI: 'https://staging.api.gespodo.com/api',
//     DEMO: 'https://staging.api.gespodo.com/demo',
//     DEMO_DOWNLOAD: 'https://staging.api.gespodo.com/demo/user/files/download',
//     CHECK_TOKEN: true,
//     PLAYSTORE_APP_URL: 'https://play.google.com/store/apps/details?id=com.gespodo',
//     REDIRECTION_SUBSCRIBE: 'https://www.gespodo.com/contact',
//     URL_CONDITION_GENERAL: 'https://www.gespodo.com/fr/CGU_Website_Gespodo.com'
// };

// export const environment = {
//     production: false,
//     URLAPI: 'https://api.gespodo.com/api',
//     DEMO: 'https://api.gespodo.com/demo',
//     DEMO_DOWNLOAD: 'https://api.gespodo.com/demo/user/files/download',
//     CHECK_TOKEN: true,
//     PLAYSTORE_APP_URL: 'https://play.google.com/store/apps/details?id=com.gespodo',
//     REDIRECTION_SUBSCRIBE: 'https://www.gespodo.com/contact',
//     URL_CONDITION_GENERAL: 'https://www.gespodo.com/fr/CGU_FOOTSCAN3D'
// };
