import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CardsStatus} from '../_models/cardsStatus';

@Injectable({providedIn: 'root'})

export class CardsStatusService {
    public static readonly BASEURL = '/cards/templates';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<CardsStatus[]>(environment.URLAPI + CardsStatusService.BASEURL);
    }
}
