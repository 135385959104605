import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UsersPatients } from '../_models/usersPatients';

@Injectable({ providedIn: 'root' })

export class UsersWaitingLineService {
    public static readonly BASEURL = '/users/waitinglist';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<UsersPatients[]>(environment.URLAPI + UsersWaitingLineService.BASEURL);
    }

    getAllUserPatient() {
        return this.http.get<UsersPatients[]>(environment.URLAPI + UsersWaitingLineService.BASEURL + '/getAllUserPatient/');
    }

    getUserWaitingLineForPatient(hash) {
        return this.http.get<any>(environment.URLAPI + UsersWaitingLineService.BASEURL + '/' + hash);
    }

    getAttachFiles(hash, card) {
        return this.http.get<any>(environment.URLAPI + UsersWaitingLineService.BASEURL + '/' + hash + '/' + card + '/attach-files');
    }

    delete(hash) {
        return this.http.delete<any>(environment.URLAPI + UsersWaitingLineService.BASEURL + '/' + hash + '/delete');
    }
}
