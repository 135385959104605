import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UsersCardsStatus} from '../_models/usersCardsStatus';

@Injectable({providedIn: 'root'})

export class UsersCardsStatusService {
    public static readonly BASEURL = '/users/cards/status';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<UsersCardsStatus[]>(environment.URLAPI + UsersCardsStatusService.BASEURL);
    }

    createSport(sport) {
        return this.http.post(environment.URLAPI + UsersCardsStatusService.BASEURL + '/add/', sport);
    }

    editSport(sport) {
        let id = sport.id;
        return this.http.post(environment.URLAPI + UsersCardsStatusService.BASEURL + '/edit/' + id , sport);
    }

    getSport(id) {
        return this.http.get<UsersCardsStatus>(environment.URLAPI + UsersCardsStatusService.BASEURL + '/get/' + id);
    }
}
