import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Materials } from '../_models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import {Cacheable} from 'ngx-cacheable';
import { Material } from '../layout/stock-management/stock-management.component';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    cache: true
};

@Injectable({ providedIn: 'root' })

export class MaterialService {
    public static readonly BASEURL = '/materials';
    router;
    searchValue = "";

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    @Cacheable()
    getAll(): Observable<Materials> {
        let url = environment.URLAPI + MaterialService.BASEURL + '/getAll/';
        return this.http.get<Materials[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }
    getAllPaginated(page, length): Observable<Materials> {
        let start = page * length;
        let dir= "desc"
        let column = "0"
        let data = "id"
        let url =`${environment.URLAPI}${MaterialService.BASEURL}/?length=${length}&order[0][column]=${column}&columns[0][data]=${data}&order[0][dir]=${dir}&search[value]=${this.searchValue}&start=${start}`
        return this.http.get<Materials>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    getAllByPosition(): Observable<Materials> {
        let url = environment.URLAPI + MaterialService.BASEURL + '/getAllByPosition/';
        return this.http.get<Materials[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    createMaterial(material: Materials): Observable<Materials> {
        let url = environment.URLAPI + MaterialService.BASEURL + '/add/';
        return this.http.post<Materials>(url, material, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    editMaterial(material: Materials){
        let id = material.id;
        let url = environment.URLAPI + MaterialService.BASEURL + '/edit/' + id;
        return this.http.post<Materials>(url, material, httpOptions).toPromise()

    }

    editArrayOfMaterial( material: Materials){
        let url = environment.URLAPI + MaterialService.BASEURL +'/edit/multipleMaterials/';
        return this.http.post<Material>(url, material,httpOptions).toPromise();
    }

    getMaterial(id): Observable<Materials> {
        let url = environment.URLAPI + MaterialService.BASEURL + '/get/' + id;
        return this.http.get<Materials>(url)
            .pipe(
                catchError(this.handleError)
            );
    }


    getPositionsMaterial(): Observable<Materials> {
        let url = environment.URLAPI + MaterialService.BASEURL + '/getPositions/';
        return this.http.get<Materials>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteMaterial(material: Materials): Observable<Materials[]> {
        let id = material.id;
        let url = environment.URLAPI + MaterialService.BASEURL + '/delete/' + id;
        return this.http.delete( url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
