import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Card } from '../_models/card';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Files } from '../_models';
import { Cacheable } from 'ngx-cacheable';
import { SelectedDataService } from '../_services/cardCreation/selected-data.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })

export class CardService {

    public static readonly STATUS_NO_CONFIRMED = 1;
    public static readonly STATUS_CONFIRMED = 2;
    public static readonly STATUS_CAD = 3;
    public static readonly STATUS_CAM = 6;
    public static readonly STATUS_SENDED = 4;
    public static readonly STATUS_BILLED = 5;

    public static readonly TYPE_PRODUCT_SEMMELLE = 1;
    public static readonly TYPE_PRODUCT_POSTURO = 2;
    public static readonly TYPE_PRODUCT_SANDALES = 3;
    public static readonly TYPE_PRODUCT_TONG = 4;
    public static readonly TYPE_PRODUCT_USINAGE = 5;
    public static readonly TYPE_PRODUCT_SHOES = 6;
    public static readonly TYPE_PRODUCT_OP3D = 7;
    public static readonly TYPE_PRODUCT_3DP = 8;
    public static readonly TYPE_PRODUCT_4DP = 9;
    public static readonly TYPE_PRODUCT_FDM = 10;

    public static readonly Finishing_RECOVERY_NON_COLLE = 3;
    public static readonly Finishing_RECOVERY_COLLE = 4;
    public static readonly Finishing_RECOVERY_NON_COLLE_3DP = 6;
    public static readonly Finishing_RECOVERY_COLLE_3DP = 7;

    public static readonly MODEL_SEMMELLE_New_pair_Choice1 = 1; // Design et Usinage
    public static readonly MODEL_SEMMELLE_New_pair_Choice2 = 2; // usinage
    public static readonly MODEL_SEMMELLE_New_pair_Choice3 = 3;
    public static readonly MODEL_SEMMELLE_Second_pair = 4;
    public static readonly MODEL_POSTURO_NEW_PAIR = 5;
    public static readonly MODEL_SANDALE_CANNES = 6;
    public static readonly MODEL_TONG_MODELE_X = 10;
    public static readonly MODEL_TONG_MODELE_Y = 11;
    public static readonly MODEL_4DP_DESIGN_USINAGE = 30;
    public static readonly MODEL_4DP_USINAGE = 31;
    public static readonly MODEL_FDM_DESIGN_USINAGE = 32;
    public static readonly MODEL_FDM_USINAGE = 33;

    public static readonly MATERIEL_BRUIN = 2;
    public static readonly MATERIEL_NOIR = 14;

    public static readonly FLANGUE_BRUIN = 2;
    public static readonly FLANGUE_NOIR = 1;


    public static readonly BASEURL = '/cards';
    public static readonly RAPPORTURL = '/cards/reports'
    router;


    constructor(private http: HttpClient, routeur: Router, private selectedDataService: SelectedDataService) {
        this.router = routeur;
    }

    getAll() {
        return this.http.get<Card[]>(environment.URLAPI + CardService.BASEURL + '/');
    }

    getWaitingLine(hash) {
        return this.http.get<any>(environment.URLAPI + CardService.BASEURL + '/' + hash + '/waitinglist');
    }

    @Cacheable()
    getAllShoesModels() {
        return this.http.get<any>(environment.URLAPI + CardService.BASEURL + '/getAllShoesModels/');
    }

    @Cacheable()
    getAllShipping() {
        return this.http.get<Card[]>(environment.URLAPI + '/shippings/active');
    }

    @Cacheable()
    getAllFinishing() {
        return this.http.get<Card[]>(environment.URLAPI + CardService.BASEURL + '/getAllFinishing/');
    }

    @Cacheable()
    getAllFlangeColor() {
        return this.http.get<Card[]>(environment.URLAPI + CardService.BASEURL + '/getAllFlangeColor/');
    }

    @Cacheable()
    getAllShoesTypes() {
        return this.http.get<Card[]>(environment.URLAPI + CardService.BASEURL + '/getAllShoesTypes/');
    }

    getFootSpecs(id) {
        return this.http.get<Card[]>(environment.URLAPI + CardService.BASEURL + '/' + id + '/getFootSpecs/');
    }

    getCard(hash): Observable<Card> {
        let url = environment.URLAPI + CardService.BASEURL + '/getByHash/' + hash;
        return this.http.get<Card>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    getPatientSport(hash): Observable<Card> {
        let url = environment.URLAPI + CardService.BASEURL + '/getSportByHash/' + hash;
        return this.http.get<Card>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteCard(card: any): Observable<Card[]> {
        let hash = card.hash;
        let url = environment.URLAPI + CardService.BASEURL + '/softdelete/' + hash;
        return this.http.delete(url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    /**
     * retourne un total de fiches par status
     */
    getCountBystatus() {
        return this.http.get<any>(environment.URLAPI + CardService.BASEURL + '/count/by/group');
    }

    /**
     * fait passer une fiche au step suivant
     * @param hash
     */
    nextStepForStatus($hash) {
        return this.http.put<any>(environment.URLAPI + CardService.BASEURL + '/' + $hash + '/nextstep', {});
    }

    /**
     * fait passer une fiche au step précédent
     * @param hash
     */
    previousStepForStatus($hash) {
        return this.http.put<any>(environment.URLAPI + CardService.BASEURL + '/' + $hash + '/previousstep', {});
    }

    changeStatus(hash, newStatusId) {
        // /updateCardStatus/{cardHash}/{newStatusId}
        const url = environment.URLAPI + CardService.BASEURL + '/' + 'updateCardStatus/'
        return this.http.post<any>(url + hash + '/' + newStatusId, {});
    }

    /**
     * download fiche pdf rapport
     * @param hash
     */
    public downloadResourceReport(hash: string) {
        return this.http.get(environment.URLAPI + CardService.BASEURL + '/pdf/report/' + hash,
            { observe: 'response', responseType: 'blob' });
    }

    editCard(statusId: number, card: any, footspecs, hash): Observable<Card> {

        let selectedDate = card['card_data_selectedShippingDate']
        let selectedFinishingId = card['finishing_id']
        let selectedRecoveryId = card['recovery_id']
        
        
        if (statusId == 1 && this.selectedDataService.mode !== 'edit') {
            selectedDate = null;
        }

        const date = selectedDate
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, -1);
        selectedDate = localISOTime
        if (selectedDate.includes("1970") || selectedDate.includes("1969")) {
            selectedDate = null;
        }

        if (selectedFinishingId != 3 && selectedFinishingId != 4) {
            selectedRecoveryId = "";
        }

        card.footspecs = footspecs;
        let url = environment.URLAPI + CardService.BASEURL + '/' + hash + '/edit/';
        return this.http.post<Card>(url, card, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    createCard(card: any, footspecs): Observable<Card> {
        card.footspecs = footspecs;
        let url = environment.URLAPI + CardService.BASEURL + '/add/';
        return this.http.post<Card>(url, card, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    /**
     * download fiche pdf labo
     * @param hash
     */
    public downloadResource(hash: string) {
        return this.http.get(environment.URLAPI + CardService.BASEURL + '/pdf/labo/' + hash,
            { observe: 'response', responseType: 'blob' });
    }

    /**
     * Retourne la liste par status
     * @param currentStep
     */
    getUndoList(currentStep: string) {
        return this.http.get<any>(environment.URLAPI + CardService.BASEURL + '/undo/' + currentStep);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    /**
     * swith pour le champs vérifié sur les fiches
     * @param $hash
     */
    switchVerified($hash) {
        return this.http.put<any>(environment.URLAPI + CardService.BASEURL + '/' + $hash + '/switchverified', {});
    }

    getFiles(hash) {
        return this.http.get<any>(environment.URLAPI + CardService.BASEURL + '/' + hash + '/files/', {}).toPromise();
    }

    uploadfilesRoute(hash) {
        return environment.URLAPI + CardService.BASEURL + '/' + hash + '/file/upload';
    }

    uploadRapportFilesRoute(id) {
        return environment.URLAPI + CardService.RAPPORTURL + '/' + id + '/file/upload';
    }

    downloadDesignFiles() {
        return this.http.get(environment.URLAPI + CardService.BASEURL + '/pdf/reportdesign',
            { observe: 'response', responseType: 'blob' });
    }

    getAllResources() {
        return this.http.get(environment.URLAPI + '/resources');
    }

    linkPodoCad(cardHash, cadHash) {
        return this.http.put(environment.URLAPI + '/cards/link-podocad/' + cardHash + '/' + cadHash, {});
    }
}
