import {Observable, Subject} from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Card } from '../../_models/card';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DatatableService {

    items = [];
    status = <any>[];
    paramQuery;

    searchValue = "";
    itemsNumbers = [10, 25, 50, 100];
    startingResultIndice = 0;
    endingResultIndice = 0;
    limitResultNumber = 10;
    currentPage = 1;
    totalPage = 0;
    totalItems = 0;
    direction = "desc";
    sortedColumnIndice = 0;
    lastLimit = 10;
    model;
    heading;
    translatedHeading;
    filterAction;

    router;
    isLoading = false;

    constructor(private http: HttpClient) {
        if (localStorage.getItem('limitResultPerPage')) {
            this.limitResultNumber = parseInt(localStorage.getItem('limitResultPerPage'), 10);
            this.lastLimit = this.limitResultNumber;
        }
    }

    private _listners = new Subject<any>();

    getListObservers() {
       return this._listners.observers;
    }

    listen(): Observable<any> {
        this.removeObserver();
        return this._listners.asObservable();
    }

    filter(filterBy: string, val: any) {
        this._listners.next({key: filterBy, value: val});
    }

    removeObserver() {
        this._listners.observers = [];
    }

    getDataFromAPI(model, shouldAddSlash = true, statusEvent) {
        if (shouldAddSlash) {
            model += '/';
        }

        console.log('direction', this.direction)
        console.log('sortedColumn', this.sortedColumnIndice)
        console.log('searchValue', this.searchValue)

        let urlParams = "?" + "length=" + this.limitResultNumber;
        urlParams += "&" + "order%5B0%5D%5Bcolumn%5D=" + this.sortedColumnIndice;

        urlParams += "&" + "columns%5B" + this.sortedColumnIndice + "%5D%5Bdata%5D=" + this.heading[this.sortedColumnIndice];

        if (this.direction) {
            urlParams += "&" + "order%5B0%5D%5Bdir%5D=" + this.direction;
        }
        if (this.searchValue) {
            urlParams += "&" + "search%5Bvalue%5D=" + this.searchValue;
        }
        let newOffset;
        if (this.limitResultNumber != this.lastLimit) {
            newOffset = this.startingResultIndice;
        } else {
            const currentPage = this.getCurrentPage();
            newOffset = this.limitResultNumber * (currentPage - 1);
        }
        urlParams += "&" + "start=" + newOffset;

        if (this.paramQuery && this.paramQuery.status && statusEvent) {
            urlParams = "?" + "length=" + this.limitResultNumber + "&filter%5Bstatus%5D=" + this.paramQuery.status;
        } else if (this.paramQuery && this.paramQuery.status) {
            urlParams += "&" + "filter%5Bstatus%5D=" + this.paramQuery.status;
        }
        
        if (this.paramQuery && this.paramQuery.user) {
            urlParams += "&" + "filter%5Buser%5D=" + this.paramQuery.user;
        }
        console.log("urlparams", urlParams)
        

        return this.http.get<any>(environment.URLAPI + '/' + model.toLowerCase() + urlParams);
    }

    getAllStatus() {
        return this.http.get<any>(environment.URLAPI + '/status');
    }

    public getPagination() {
        let arr: (number|string)[];
        let max = this.totalPage;
        if (max < 8) {
            return Array.from({length: max}, (v, k) => k+1);
        }
        if (this.getCurrentPage() < 5) {
            arr = [1, 2, 3, 4, 5, "...", max];
            return arr;
        }
        if (this.getCurrentPage() > max - 4) {
            arr = [1, "...", max - 4, max - 3, max - 2, max -1, max];
            return arr;
        }
        return [1, "...", +this.getCurrentPage() - 1, this.getCurrentPage(), +this.getCurrentPage() + 1, "...", max];
    }

    public setPage(page, router = null) {
        //console.log('asked to set page to ', page, ' in service');
        this.currentPage = page;
        if (page == "...") {
            return;
        }
        let newQueryParam;
        if (this.paramQuery) {
            newQueryParam = { ...this.paramQuery, page: page };
        } else {
            newQueryParam = { page: page };
        }

        const componentUrl = router.url.split('?')[0];
        if (router) {
            if (typeof(page) !== 'undefined' && page !== "") {
                router.navigate([componentUrl], { queryParams: newQueryParam });
            } else {
                router.navigate([componentUrl], {});
            }
        }
    }

    public nextPage() {
        if (this.getCurrentPage() == this.totalPage) {
            return;
        }
        this.setPage(+this.getCurrentPage() + 1, this.router);
    }

    public previousPage() {
        if (this.getCurrentPage() == 1) {
            return;
        }
        this.setPage(this.getCurrentPage() - 1, this.router);
    }

    public isCurrentPage(page) {
        return page == this.getCurrentPage();
    }

    public loadStatus() {
        this.getAllStatus().subscribe(response => {
            this.status = response.datas;
        });
    }

    public loadDatas(statusEvent = false) {
        let model = this.model;
        let shouldAddSlash = true;
        if (model === "usersCards") {
            model = "users/cards";
        } else if (model === "usersPatients") {
            model = "users/patients/list";
        } else if (model === "usersCardsTemplates") {
            model = "users/cards/templates";
        } else if (model === "cardsTemplates") {
            model = "cards/templates";
        } else if (model === "users") {
            shouldAddSlash = false;
        }
        this.isLoading = true;
        this.getDataFromAPI(model, shouldAddSlash, statusEvent).subscribe(response => {
            this.items = this.applyFilter(response.datas);
            this.startingResultIndice = response.meta.offset;
            this.adaptCurrentPage(response.meta.current_page);
            this.lastLimit = response.meta.limit;
            this.endingResultIndice = response.meta.offset + (this.limitResultNumber - 1);
            this.totalPage = response.meta.last_page;
            this.totalItems = response.meta.total_items;
            this.isLoading = false;
        }, (error) => {
            console.log(error);
            this.isLoading = false;
        });
        
    }

    public applyFilter(data) {
        if (this.filterAction) {
            return this.filterAction(data)
        }
        return data
    }

    public adaptCurrentPage(rightPage) {
        if (this.currentPage != rightPage) {
            console.log('rightPage is ', rightPage, ' so we redirect because current page is ', this.currentPage);
            this.setPage(rightPage, this.router);
        }
    }

    public filterItems() {
        let array = [];
        let elem, keys;
        let neededKeys = this.heading;
        let newObject = {};
        console.log('ITEMS', this.items)
        for (let i = 0 ; i < this.items?.length ; ++i) {
            elem = this.items[i];
            newObject = Object.keys(elem)
            .filter(key => neededKeys.includes(key))
            .reduce((obj, key) => {
                obj[key] = elem[key];
                return obj;
            }, {});
            this.addCustomsFields(newObject, elem);
            array.push(newObject);
        }
        return array;
    }

    public addCustomsFields(newObject, fullObject) {
        if (this.model == "cards" || this.model == "usersCards") {
            newObject.patient = fullObject.lastname + " " + fullObject.firstname;
            newObject.podo = fullObject.podo_lastname + " " + fullObject.podo_firstname;
        } else if (this.model == "cardsTemplates") {
            if (fullObject.podo_firstname || fullObject.podo_lastname) {
                newObject.podo = fullObject.podo_lastname + " " + fullObject.podo_firstname;
            } else {
                newObject.podo = "Template public";
            }
        }
    }

    public changeLimitResult() {
        localStorage.setItem('limitResultPerPage', this.limitResultNumber.toString());
        this.loadDatas();
    }

    public setSorting(indice) {
        if (indice != this.sortedColumnIndice) {
            this.sortedColumnIndice = indice;
            this.direction ='asc';
        } else {
            this.changeDirection();
        }
        this.loadDatas();
    }

    public changeDirection() {
        this.direction = this.direction == 'asc' ? 'desc' : 'asc';
    }

    public getThClass(item) {
        let className = 'sorting';
        if (item == this.sortedColumnIndice) {
            if (this.direction == 'asc') {
                className += '_asc';
            } else {
                className += '_desc';
            }
        }
        return className;
    }

    public reset() {
        this.sortedColumnIndice = 0;
        this.direction = 'desc';
        this.items = [];
        this.heading = [];
        this.searchValue = "";
    }

    public getStatusTextByStatusId(id) {
        for (let i = 0 ; i < this.status.length ; ++i) {
            if (this.status[i].id === id) {
                return this.status[i].name;
            }
        }
    }

    public isAuthorized(roles, statusIndice) {

        if (roles.indexOf('ROLE_ADMIN') !== -1) {
            return true;
        }
        if (roles.indexOf('ROLE_CAD') !== -1 && this.hasCADAuthorizationOnThisAction(statusIndice)) {
            return true;
        }
        if (roles.indexOf('ROLE_CAM') !== -1 && this.hasCAMAuthorizationOnThisAction(statusIndice)) {
            return true;
        }
        if (roles.indexOf('ROLE_FINANCE') !== -1 && this.hasFinanceAuthorizationOnThisAction(statusIndice)) {
            return true;
        }
        return false;
    }

    public isAdmin(roles) {
        if (roles.indexOf('ROLE_ADMIN') !== -1) {
            return true;
        }
        return false;
    }

    public hasCADAuthorizationOnThisAction(indice) {
        if (indice == 2) {
            return true;
        }
    }

    public hasCAMAuthorizationOnThisAction(indice) {
        if (indice == 3 || indice == 6 || indice == 7) {
            return true;
        }
    }

    public hasFinanceAuthorizationOnThisAction(indice) {
        if (indice == 4) {
            return true;
        }
    }

    public getCurrentPage() {
        // let currentPage = 1;
        // if (this.paramQuery.page) {
        //     currentPage = parseInt(this.paramQuery.page, 10);
        // }
        // if (currentPage === 0) {
        //     currentPage = 1;
        // }
        // return currentPage;
        return this.currentPage;
    }

    public initCurrentPage() {
        if (this.paramQuery.page) {
            this.currentPage = this.paramQuery.page;
            console.log(this.paramQuery.page);
        } else {
            this.currentPage = 1;
        }

    }

}
