import { Injectable } from "@angular/core";
@Injectable()
export class User {
    hash: string;
    username: string;
    password: string;
    website: string;
    firstname: string;
    lastname: string;
    professionalTitle: string;
    phoneFix: string;
    phonePortable: string;
    fax: string;
    email: string;
    logo;
    newlogo;
    tva_number: string;
    address_pr_street: string;
    address_pr_number: number;
    address_pr_box: string;
    address_pr_zip: number;
    address_pr_city: string;
    address_pr_country: string;
    address_pr_type: string;
    address_cp_street: string;
    address_cp_number: number;
    address_cp_box: string;
    address_cp_zip: number;
    address_cp_city: string;
    address_cp_country: string;
    address_cp_type: string;
    largeur = true;
    detorsion = true;
    bardePronatrice = true;
    axe = true;
    courbure = true;
    hemicoupole = true;
    cuvette = true;
    epaisseur = true;
    talon = true;
    pronateur = true;
    supinateur = true;
    cuboidien = true;
    brc = true;
    brc23Int = true;
    brc23Ext = true;
    extMorton = true;
    appuiRC = true;
    sousAC = true;
    diaphysaire = true;
    ruler = true;
    posting = true;
    firstRayon = true;
    decharge = true;
    cluffy = true;
    arrPied = true;
    avPied = true;
    posturoBa = true;
    posturoBi = true;
    posturoBpi = true;
    posturoEai = true;
    posturoEca = true;
    posturoEmi = true;
    posturoEpi = true;
    painlevel1 = true;
    painlevel2 = true;
    painlevel3 = true;
    userRole = '5';
    companies;
    address;
    finishing: number;
    shipping: number;
    shippingDays: string;
    accountBalance: {
        negativeLimit: number,
        alertLimit: number
    };
    negativeLimit: number;
    alertLimit: number;
    shippingBox: number;
    shippingBox2: number;
    personalNote: string = null;
    podoCadHash: string = null;
    podoCad;
}
