import {Observable, Subject} from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalUndoService {
    constructor() {
    }

    private _listners = new Subject<any>();

    getListObservers() {
       return this._listners.observers;
    }

    listen(): Observable<any> {
        this.removeObserver();
        return this._listners.asObservable();
    }

    filter(filterBy: string, val: any) {
        this._listners.next({key: filterBy, value: val});
    }

    removeObserver() {
        this._listners.observers = [];
    }
}
