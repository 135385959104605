import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FlangesColor } from '../_models/flangesColor';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import {Cacheable} from 'ngx-cacheable';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    cache: true
};

@Injectable({ providedIn: 'root' })

export class FlangesColorService {
    public static readonly BASEURL = '/flangescolor';
    router;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    @Cacheable()
    getAll(): Observable<FlangesColor> {
        let url = environment.URLAPI + FlangesColorService.BASEURL + '/getAll/';

        return this.http.get<FlangesColor[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    createFlangesColor(flangesColor: FlangesColor): Observable<FlangesColor> {
        let url = environment.URLAPI + FlangesColorService.BASEURL + '/add/';
        return this.http.post<FlangesColor>(url, flangesColor, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    editFlangesColor(flangesColor: FlangesColor): Observable<FlangesColor> {
        let id = flangesColor.id;
        let url = environment.URLAPI + FlangesColorService.BASEURL + '/edit/' + id;
        return this.http.post<FlangesColor>(url, flangesColor, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getFlangesColor(id): Observable<FlangesColor> {
        let url = environment.URLAPI + FlangesColorService.BASEURL + '/get/' + id;
        return this.http.get<FlangesColor>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteFlangesColor(flangesColor: FlangesColor): Observable<FlangesColor[]> {
        let id = flangesColor.id;
        let url = environment.URLAPI + FlangesColorService.BASEURL + '/delete/' + id;
        return this.http.delete( url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
