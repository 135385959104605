import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Message } from '../_models/message';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {Router} from '@angular/router';
import {Sport} from '../_models/sport';
import {SportService} from './sport.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })

export class MessageService {
    public static readonly BASEURL = '/messages';
    router;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    getAll(): Observable<Message> {
        let url = environment.URLAPI + MessageService.BASEURL;
        return this.http.get<Message[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    getActiveMessage(): Observable<Message> {
        let url = environment.URLAPI + MessageService.BASEURL + '/active/';
        return this.http.get<Message[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    createMessage(message: Message): Observable<Message> {
        let url = environment.URLAPI + MessageService.BASEURL + '/add/';
        return this.http.post<Message>(url, message, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    editMessage(message: Message): Observable<Message> {
        let id = message.id;
        let url = environment.URLAPI + MessageService.BASEURL + '/edit/' + id;
        return this.http.post<Message>(url, message, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getMessage(id): Observable<Message> {
        let url = environment.URLAPI + MessageService.BASEURL + '/get/' + id;
        return this.http.get<Message>(url)
            .pipe(
                catchError(this.handleError)
            );
    }
    
    deleteMessage(message: Message): Observable<Message[]> {
        let id = message.id;
        let url = environment.URLAPI + MessageService.BASEURL + '/delete/' + id;
        return this.http.delete(url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
