import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule as Ng2Charts } from 'ng2-charts';
import {TranslateModule} from '@ngx-translate/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalDeleteComponent} from './modalDelete.component';

@NgModule({
    imports: [CommonModule, Ng2Charts, TranslateModule, FormsModule, ReactiveFormsModule],
    declarations: [ModalDeleteComponent],
    exports: [ModalDeleteComponent]
})
export class ModalDeleteModule {}
