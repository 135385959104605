import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Recovery } from '../_models/recovery';
import {SportService} from './sport.service';
import {Cacheable} from 'ngx-cacheable';

@Injectable({ providedIn: 'root' })

export class RecoveryService {
    public static readonly BASEURL = '/recoveries';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Recovery[]>(environment.URLAPI + RecoveryService.BASEURL);
    }

    @Cacheable()
    getAllRecovery() {
        return this.http.get<Recovery[]>(environment.URLAPI + RecoveryService.BASEURL + '/getAllRecovery/');
    }

    createRecovery(recovery) {
        return this.http.post(environment.URLAPI + RecoveryService.BASEURL + '/add/', recovery);
    }

    editRecovery(recovery) {
        let id = recovery.id;
        return this.http.post(environment.URLAPI + RecoveryService.BASEURL + '/edit/' + id , recovery);
    }

    getRecovery(id) {
        return this.http.get<Recovery>(environment.URLAPI + RecoveryService.BASEURL + '/get/' + id);
    }

    deleteRecovery(recovery) {
        let id = recovery.id;
        return this.http.delete(environment.URLAPI + RecoveryService.BASEURL + '/delete/' + id , recovery);
    }
}
