import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Patient } from '../_models';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })

export class PatientService {
    public static readonly BASEURL = '/patients';
    router;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    getAll() {
        return this.http.get<Patient[]>(environment.URLAPI + PatientService.BASEURL);
    }

    getPatient(hash): Observable<Patient> {
        let url = environment.URLAPI + PatientService.BASEURL + '/getByHash/' + hash;
        return this.http.get<Patient>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    createPatient(patient: Patient): Observable<Patient> {
        let url = environment.URLAPI + PatientService.BASEURL + '/add/';
        return this.http.post<Patient>(url, patient, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    editPatient(patient: Patient): Observable<Patient> {
        let hash = patient.hash;
        let url = environment.URLAPI + PatientService.BASEURL + '/edit/' + hash;
        return this.http.post<Patient>(url, patient, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    deletePatient(patient: Patient): Observable<Patient> {
        let hash = patient.hash;
        let url = environment.URLAPI + PatientService.BASEURL + '/softdelete/' + hash;
        return this.http.delete( url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
