import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Shipping } from '../_models/shipping';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import {Cacheable} from 'ngx-cacheable';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    cache: true
};

@Injectable({ providedIn: 'root' })

export class ShippingService {
    public static readonly BASEURL = '/shippings';
    router;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    @Cacheable()
    getAll(): Observable<Shipping> {
        let url = environment.URLAPI + ShippingService.BASEURL + '/';
        console.log(url);
        return this.http.get<Shipping[]>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    createShipping(shipping: Shipping): Observable<Shipping> {
        let url = environment.URLAPI + ShippingService.BASEURL + '/';
        return this.http.post<Shipping>(url, shipping, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    editShipping(shipping: Shipping): Observable<Shipping> {
        let id = shipping.id;
        let url = environment.URLAPI + ShippingService.BASEURL + '/' + id;
        return this.http.put<Shipping>(url, shipping, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getShipping(id): Observable<Shipping> {
        let url = environment.URLAPI + ShippingService.BASEURL + '/' + id;
        return this.http.get<Shipping>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteShipping(shipping: Shipping): Observable<Shipping[]> {
        let id = shipping.id;
        let url = environment.URLAPI + ShippingService.BASEURL + '/' + id;
        return this.http.delete( url, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
