import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import {Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import {UserService} from './user.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({providedIn: 'root'})

export class UsersProfilService {
    public static readonly BASEURL = '/users';
    router;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    getUsersProfil() {
        let url = environment.URLAPI + UserService.BASEURL + '/profil';
        return this.http.get(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    editProfilUser(user: User): Observable<User> {
        let hash = user.hash;

        let url = environment.URLAPI + UserService.BASEURL + '/profil/edit/' + hash;
        return this.http.post<User>(url, user, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    public getImageProfil(hash) {
        return environment.URLAPI + UserService.BASEURL + '/' + hash + '/profil/image';
    }

    urlUploadSpecUser(hash) {
        return environment.URLAPI + UserService.BASEURL + '/' + hash + '/' + 'logo/upload';
        // return environment.URLAPI + FileService.BASEURL + '/' + 'upload';
    }

    urlUpload() {
        return environment.URLAPI + UserService.BASEURL + '/' + 'logo/upload';
        // return environment.URLAPI + FileService.BASEURL + '/' + 'upload';
    }

    uploadSpecUser(hash, formData) {
        return this.http.post(this.urlUploadSpecUser(hash), formData, {reportProgress: true, observe: 'events'});
    }

    upload(formData) {
        return this.http.post(this.urlUpload(), formData, {reportProgress: true, observe: 'events'});
    }
}
