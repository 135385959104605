<div class="row">
    <div class="col-xl-12">
        <h2 class="page-header">
            {{ heading | translate }}
        </h2>
        <div class="add-new" *ngIf="link_new">
            <a class="btn btn-info btn-sm" [routerLink]="['/'+link_new]">{{ 'Add new' | translate }}</a>
        </div>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <i class="fa fa-dashboard"></i> <a href="Javascript:void(0)" [routerLink]="['/dashboard']"> {{ 'Dashboard' | translate }}</a>
            </li>
            <li class="breadcrumb-item active"><i class="fa {{icon}}"></i> {{ heading | translate }}</li>
        </ol>
    </div>
</div>
