import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from '../../_services';

@Injectable({ providedIn: 'root' })
export class HighlevelGuard implements CanActivate {

    constructor(private router: Router, private autho: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const userRoles = this.autho.getRoles();

        if (userRoles != null && (userRoles.indexOf('ROLE_ADMIN') !== -1 || userRoles.indexOf('ROLE_CAD') !== -1 || userRoles.indexOf('ROLE_CAM') !== -1) || userRoles.indexOf('ROLE_FINANCE') !== -1) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/dashboard'], { queryParams: {}});
        return false;
    }
}
