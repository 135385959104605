import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Demo} from '../_models/demo';
import { Observable, of } from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({providedIn: 'root'})

export class DemoService {
    router;
    data;

    constructor(private http: HttpClient, routeur: Router) {
        this.router = routeur;
    }

    createDemo(demo: Demo): Observable<Demo> {
        let url = environment.DEMO + '/users/subscribe';
        return this.http.post<Demo>(url, demo, httpOptions);
    }

    getUserDemo(hash): Observable<Demo> {
        let url = environment.DEMO + '/users/' + hash;
        return this.http.get<Demo>(url).pipe(
            catchError(this.handleError)
        );
    }

    getUserFilesDemo(token): Observable<File> {
      let url = environment.DEMO + '/user/files/?token=' + token;
      return this.http.get<File>(url);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
