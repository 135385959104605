<div class="row">
  
  <div class="col-sm-12">
    <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
      <div class="dataTables_length" id="datatable_length">
        <label>
          {{ 'display' | translate }}
          <select
            [(ngModel)]="_dataTableService.limitResultNumber"
            (ngModelChange)="_dataTableService.changeLimitResult()"
            >
            <option
              *ngFor="let option of _dataTableService.itemsNumbers"
              [value]="option"
              >
              {{option}}
            </option>
          </select>
          {{ 'elements' | translate }}
        </label>
      </div>
      <div id="datatable_filter" class="dataTables_filter">
        <span
          *ngIf="_dataTableService && _dataTableService.isLoading"
          class="spinner-border text-info spinner-border-sm"
          role="status"
          aria-hidden="true"
          ></span>
        <label>
          {{ 'search' | translate }}
          <input
            type="search"
            class=""
            placeholder=""
            [(ngModel)]="_dataTableService.searchValue"
            (ngModelChange)="_dataTableService.loadDatas()"
          />
        </label>
      </div>
      <table id="datatable" class="table table-striped table-bordered dataTable no-footer" cellspacing="0" width="100%">
        <thead>
          <tr role="row" *ngIf="isHeaderSet">
            <th
              *ngFor="let item of heading; let i = index"
              [ngClass]="_dataTableService.getThClass(i)"
              tabindex="0"
              rowspan="1"
              colspan="1"
              (click)="_dataTableService.setSorting(i)"
              aria-label="Activer pour trier la colonne par ordre croissant"
              >
              {{item | translate}}
            </th>
            <th>{{ 'actions' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          
          <tr role="row" *ngFor="let item of _dataTableService.filterItems(); let i = index;" class="even">
            <td *ngFor="let key of heading">
                <span *ngIf="key != 'verified' && key != 'sendingDateLabo' && key != 'name'">{{ item[key] }}</span>
                <span *ngIf="key === 'name'">{{ item[key] | translate }}</span>
                <span *ngIf="key === 'sendingDateLabo' && !_dataTableService.items[i].urgentConception"> {{ showDayName(item[key]) }} </span>
                <span *ngIf="key === 'sendingDateLabo' && _dataTableService.items[i].urgentConception" style="color:red;">{{ showDayName(item[key]) }}</span>
                <span
                  *ngIf="key == 'verified'"
                  class="btn btn-primary card-revified"
                  >
                  {{yesOrNo(item[key]) | translate}}
                </span>
            </td>
            
            <td>
              <span *ngFor="let action of filteredActions(i)">
                <span *ngIf="_dataTableService.items[i].status_id >= 3">
                  <span
                  *ngIf="action.action !== 'link-podo-cad' "
                  (click)="doAction(action, i)"
                  [ngClass]="[action.button,'btm-sm','openlink']"
                  [title]="action.tooltip"
                  >
                  <i [className]="action.icon"></i>
                  </span>
                </span>

                <span *ngIf="_dataTableService.items[i].status_id < 3">
                  <span
                  *ngIf="_dataTableService.items[i].cad !== ' ' && action.action !== 'link-podo-cad'"
                  (click)="doAction(action, i)"
                  [ngClass]="[action.button,'btm-sm','openlink']"
                  [title]="action.tooltip"
                  >
                  <i [className]="action.icon"></i>
                  </span>
                </span>
                <span *ngIf="_dataTableService.items[i].status_id < 3">
                  <span
                  *ngIf="_dataTableService.items[i].cad === ' '"
                  (click)="doAction(action, i)"
                  [ngClass]="[action.button,'btm-sm','openlink']"
                  [title]="action.tooltip"
                  >
                  <i [className]="action.icon"></i>
                  </span>
                </span>
                <span *ngIf="!_dataTableService.items[i].status_id">
                  <span
                  (click)="doAction(action, i)"
                  [ngClass]="[action.button,'btm-sm','openlink']"
                  [title]="action.tooltip"
                  >
                  <i [className]="action.icon"></i>
                  </span>
                </span>
              </span>

              <span
                *ngIf="shouldDisplayCardStatus(i) && _dataTableService.items[i].status_id !== 1"
                class="btn btn-secondary changecardstatut"
                [ngClass]="inProgress ? 'cursorInProgress' : ''"
                [ngStyle]="{'background-color': getBackgroundByStatus(_dataTableService.items[i].status_id)}"
                (click)="updateStatus(_dataTableService.items[i], $event)"
                >
                <i class="fa fa-database"></i>
                <span *ngIf="inProgress!==item.id">{{_dataTableService.items[i].status_name | translate}}</span>
                <span *ngIf="inProgress===item.id">{{ 'in_progress' | translate }}</span>
              </span>
              <span
                *ngIf="shouldDisplayCardStatus(i) && _dataTableService.items[i].status_id === 1"
                class="btn btn-secondary changecardstatut"
                style="cursor: auto;"
                [ngClass]="inProgress ? 'cursorInProgress' : ''"
                [ngStyle]="{'background-color': getBackgroundByStatus(_dataTableService.items[i].status_id)}"
                >
                <i class="fa fa-database"></i>
                <span *ngIf="inProgress!==item.id">{{_dataTableService.items[i].status_name | translate}}</span>
                <span *ngIf="inProgress===item.id">{{ 'in_progress' | translate }}</span>
              </span>

            </td>
          </tr>
        </tbody>
      </table>
      <div class="dataTables_info" id="datatable_info" >
        {{ 'display_of_element' | translate }}
        {{_dataTableService.startingResultIndice}}
        {{ 'to' | translate }}
        {{_dataTableService.endingResultIndice}}
        {{ 'out_of' | translate }}
        {{_dataTableService.totalItems}}
        {{ 'elements' | translate }}
        <span
          *ngIf="_dataTableService.isLoading"
          class="spinner-border text-info spinner-border-sm"
          role="status"
          aria-hidden="true">
        </span>
      </div>
      <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
        <a
          class="paginate_button previous"
          [ngClass]="{'disabled': _dataTableService.getCurrentPage()==1}"
          (click)="_dataTableService.previousPage()"
          id="datatable_previous"
          >
          {{ 'previous' | translate }}
        </a>
        <span>
          <a
            *ngFor="let page of _dataTableService.getPagination()"
            [ngClass]="{'current': _dataTableService.isCurrentPage(page), 'paginate_button': true}"
            (click)="setPage(page)"
            >
            {{page}}
          </a>
        </span>
        <a
          class="paginate_button next"
          [ngClass]="{'disabled': _dataTableService.getCurrentPage()==_dataTableService.totalPage}"
          (click)="_dataTableService.nextPage()"
          id="datatable_next"
          >
          {{ 'next' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
<app-modal-datatable-delete></app-modal-datatable-delete>
