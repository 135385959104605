import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule as Ng2Charts } from 'ng2-charts';
import {TranslateModule} from '@ngx-translate/core';

import { MessagesRoutingModule } from './messages-routing.module';
import { MessagesComponent } from './messages.component';

@NgModule({
    imports: [CommonModule, Ng2Charts, TranslateModule, MessagesRoutingModule],
    declarations: [MessagesComponent],
    exports: [MessagesComponent]
})
export class MessagesModule {}
