import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UsersFiles } from '../_models/usersFiles';

@Injectable({ providedIn: 'root' })

export class UsersFilesService {
    public static readonly BASEURL = '/users/files';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<UsersFiles[]>(environment.URLAPI + UsersFilesService.BASEURL);
    }
}
