export class ListSelectField {
    constructor(name, denomination, references, source, value) {
        this.name = name;
        this.denomination = denomination;
        this.references = references;
        this.source = source;
        this.value = value;
    }

    name: '';
    denomination = '';
    references = '';
    source = '';
    value: boolean;
}
